import React from 'react'
import PageTitle from '../global/titles/pagetitle'
import ClassNames from 'classnames'
import { FormattedMessage } from 'react-intl'

const Hero = ({pageTitleSub, pageTitleMain, pageIntro, featImage, classManipulators }) => (
    <section className={ClassNames('herostd bg-white', classManipulators)} >
        {/* Title */}
        <div className="row expanded">
            <div className="small-12 medium-push-1 medium-10 large-push-2 large-6 columns print-hero__std">
                <div className="herostd-title mpad">
                    <PageTitle 
                        subTitle={pageTitleSub}
                        mainTitle={pageTitleMain}
                        classes="dark heading"
                    />
                    <p className="dark"><FormattedMessage id={pageIntro}/></p>
                </div>
            </div>
        </div>
        {/* Image */}
        {featImage &&
          <div className="herostd-image__wrapper">
              <div className="row expanded collapse">
                  <div className="small-12 large-push-1 large-10 columns">
                      <div className="herostd-image">
                          <img src={featImage} alt="Hero Image"/>
                      </div>
                  </div>
              </div>
          </div>
        }
    </section>
)

export default Hero



