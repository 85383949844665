import React from "react";
import ornament from "../../images/ornament-services.svg";
import { FormattedMessage } from "react-intl";
import { StickyContainer, Sticky } from 'react-sticky';
import cx from 'classnames';

const services = [
	{
		heading: "experience",
		content: "state_of_the_art_solutions_para"
	},
	{
		heading: "support",
		content: "we_provide_fanantical_support_para"
	},
	{
		heading: "customization",
		content: "customisable_approach_para"
	}
];


const isSmallerThan = (pix) => {
    if (typeof document !== 'undefined') {
        return document.documentElement.clientWidth < pix;
    }
    return false;
}


const Services = () => (
	<StickyContainer>
		<section className="solser bg-white pad-smallest">
			<div className="row expanded">
				{/* Heading */}
				<div className="small-12 medium-push-1 medium-10 large-push-2 large-3 columns print-solser__title">
					{!isSmallerThan(1200) ? (
						<Sticky topOffset={0} bottomOffset={50}>
						{({ style, isSticky }) => (
							<div className={cx("solser-heading mpad", { [ "sticky" ]: isSticky})} style={style}>
								<em>Our</em>
								<h2 className="dark heading">
									<FormattedMessage id="services" />
								</h2>
							</div>
						)}
						</Sticky>
					) : (
						<div className="solser-heading mpad">
							<em>Our</em>
							<h2 className="dark heading">
								<FormattedMessage id="services" />
							</h2>
						</div>
					)}
				</div>

				{/* Accordians */}
				<div className="small-12 medium-pull-1 medium-10 large-pull-2 large-5 columns">
					<div className="solser-acc mpad">
						{services.map((service, index) => {
							return (
								<div className="service-entry" key={index}>
									<div className="heading"><FormattedMessage id={service.heading} tagName="h5"/></div>
									<p className="dark">
										<FormattedMessage id={service.content} />
									</p>
								</div>
							);
						})}

						<a className="button dark" href="/solutions/custom-solutions">
							<span />
							<FormattedMessage id="find_out_more" tagName="div" />
						</a>
					</div>
				</div>
			</div>

			{/* Ornament */}
			{/* <div className="solser-ornament">
				<img src={ornament} alt="Ornament" />
			</div> */}
		</section>
	</StickyContainer>
);

export default Services;




