import React from 'react'
import Slider from 'react-slick'
import Equalizer from 'react-equalizer'
import ImageSlide from './image-slide'
import ContentSlide from './content-slide'
import Icon from '../../global/icons/standard-icon'

class FeatSlider extends React.Component {
    constructor(props) {
        super(props)
        
        this.state = { sliderImage: null, sliderContent: null }
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
    }

    updateRef = (ref, item) => {
        if (!this.state[item]) {
            this.setState({
                [item]: ref,
            });
        }
    }

    next() { this.state.slider.slickNext(); }

    previous() { this.state.slider.slickPrev(); }

    getNodes(equalizerComponent, equalizerElement) {
        return [
            this.refs.image,
            this.refs.content
        ]
    }

    render() {
        const imageSettings = {
            dots: false,
            arrows: false,
            infinite: true,
            fade: true,
            speed: 1000,
            slidesToShow: 1,
            slidesToScroll: 1,
            pauseOnHover: true,
            autoplay: true,
            autoplaySpeed: 7000,
            asNavFor: this.state.sliderContent
        };

        const contentSettings = {
            dots: false,
            arrows: false,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            pauseOnHover: true,
            variableHeight: true,
            autolay: true,
            autoplaySpeed: 7000,
            asNavFor: this.state.sliderImage
        };

        return (
            <div className="relative">
                <div className="row expanded collapse">
                    <div className="small-12 large-push-1 large-11 columns">
                        <main className="featslider">
                            <Equalizer
                                byRow={false}
                                property="height"
                                enabled={() => window.matchMedia("(min-width: 320px)").matches}
                                nodes={this.getNodes.bind(this)}
                                className="row expanded collapse"
                            >
                                {/* Image Slider */}
                                <div className="small-12 large-5 columns">
                                    <div className="featslider-image__sliderwrap" ref="image">
                                        <Slider {...imageSettings} className="featslider-image" ref={ref => {this.updateRef(ref, 'sliderImage')}}>
                                            {this.props.featSlider.map(slide => (
                                                <ImageSlide
                                                    image={slide.featImage}
                                                    key={slide.featImage}
                                                />
                                            ))}
                                        </Slider>
                                    </div>
                                </div>

                                {/* Content Slider */}
                                <div className="small-12 large-7 columns">
                                    <div className="featslider-content__wrap" ref="content">
                                        <Slider
                                            {...contentSettings}
                                            className="featslider-content"
                                            ref={ref => {
                                                this.updateRef(ref, 'slider')
                                                this.updateRef(ref, 'sliderContent')}
                                            }
                                        >

                                            {this.props.featSlider.map(slide => (
                                                <ContentSlide
                                                    subTitle={slide.subTitle}
                                                    mainTitle={slide.mainTitle}
                                                    content={slide.content}
                                                    linkTo={slide.linkTo}
                                                    linkLabel={slide.linkLabel}
                                                    key={slide.mainTitle}
                                                />
                                            ))}
                                        </Slider>
                                    </div>
                                </div>
                            </Equalizer>

                            <div className="featslider-controls">
                                <button className="featslider-controls__prev" onClick={this.previous}>
                                    <Icon icon="chevron-left" />
                                </button>

                                <button className="featslider-controls__next" onClick={this.next}>
                                    <Icon icon="chevron-right" />
                                </button>
                            </div>
                        </main>
                    </div>
                </div>
            </div>
        );
    }
}

export default FeatSlider



