import React from 'react';
import Equalizer from 'react-equalizer'
import { FormattedMessage } from 'react-intl'


class CallToAction extends React.Component {
  constructor(props) {
    super(props)
  }

  getNodes(equalizerComponent, equalizerElement) {
    return [
      this.refs.node1,
      this.refs.node2
    ]
  }

  render() {
    var large = "(min-width: 1024px)";

    return(
      <section className="cta">
        <Equalizer
          className="row expanded collapse"
          nodes={this.getNodes.bind(this)}
          byRow={false}
          property="height"
          enabled={() => window.matchMedia(large).matches}
        >
          {/* Image */}
          <div className="small-12 large-7 columns print-cta__image">
            <div className="cta-image" ref="node1">
              <img src={this.props.image} alt="Call to Action Image"/>
            </div>
          </div>

          {/* Content */}
          <div className="small-12 medium-pull-1 medium-10 large-pull-1 large-5 columns print-cta__content">
            <div className="cta-content__wrap mpad" ref="node2">
              <div className="cta-content mpad">
                <h2 className="heading"><FormattedMessage id={this.props.heading}/></h2>
                <p className="light body"><FormattedMessage id={this.props.content}/></p>
                {this.props.buttonLink === undefined ? (
                  <a className="button light" onClick={this.props.action}><span></span><FormattedMessage id={this.props.buttonLabel} tagName="div"/></a>
                ) : (
                  <a className="button light" href={this.props.buttonLink}><span></span><FormattedMessage id={this.props.buttonLabel} tagName="div"/></a>
                )}
              </div>
            </div>
          </div>
        </Equalizer>
      </section>
    )
  }
}

export default CallToAction;



