import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Link } from '../../../i18n'

const ContentSlide = ({ subTitle, mainTitle, content, linkTo, linkLabel}) => (

    <div className="featslider-content__slide">
        <div className="row expanded mpad">
            <div className="small-12 medium-push-1 medium-10 large-push-2 large-8 columns">
                <div className="featslider-content__slide--jacket">
                    <em className="light"><FormattedMessage id={subTitle}/></em>
                    <br/>
                    <h3 className="heading"><FormattedMessage id={mainTitle}/> </h3>
                    <p className="light"><FormattedMessage id={content}/></p>
                    {(linkLabel && linkTo) && <Link to={linkTo} className="button light"><span></span><FormattedMessage id={linkLabel} tagName="div"/></Link>}
                </div>
            </div>
        </div>
    </div>
)

export default ContentSlide



