import React from 'react'
import Header from '../components/global/header/header'
import Hero from '../components/heros/standard'
import Products from '../components/solutions/products'
import Services from '../components/solutions/services'
import CallToAction from '../components/global/cta'
import Footer from '../components/global/footer/footer'
import PageTransition from 'gatsby-plugin-page-transitions';

// Images
// import ctaImage from '../images/example-cta.jpg'
import ctaImage from '../images/7Z8A0083_Output.jpg'

import accounting from '../images/business-solutions/accounting.jpg'
import onlineDistribution from '../images/business-solutions/online-distribution.jpg'
import retail from '../images/business-solutions/retail.jpg'
import eCommerce from '../images/business-solutions/ecommerce.jpg'
import customSolutions from '../images/business-solutions/custom-made-solutions.jpg'
import { withIntl } from '../i18n'


// Slider Slides
const featSlider = [
  {
    featImage: accounting,
    subTitle: "small_business",
    mainTitle: "accouting",
    content: "accounting_para",
    linkTo: "/solutions/accounting",
    linkLabel: "find_out_more"
  },
  // {
  //   featImage: onlineDistribution,
  //   subTitle: "online",
  //   mainTitle: "distribution",
  //   content: "online_distribution_solution_para",
  //   linkTo: "/solutions/online-distribution",
  //   linkTo:"/case-studies",
  //   linkLabel: "find_out_more"
  // },
  // {
  //   featImage: retail,
  //   subTitle: "retail_and",
  //   mainTitle: "field_sales",
  //   content: "field_sales_solution_para",
  //   linkTo: "/solutions/retail",
  //   linkTo:"/case-studies",
  //   linkLabel: "find_out_more"
  // },
  {
    featImage: eCommerce,
    subTitle:"group_collections",
    mainTitle:"ecommerce",
    content: "ecommerce_solution_para",
    linkTo: "/solutions/ecommerce",
    linkLabel: "find_out_more"
  },
  // {
  //   featImage: customSolutions,
  //   subTitle:"custom_made",
  //   mainTitle:"solutions",
  //   content: "custom_made_soluiton",
  //   linkTo: "/solutions/custom-solutions",
  //   linkLabel: "find_out_more"
  // }
]

class SolutionsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      contactOpen: false,
    };
  }

  toggleContact = () => {
    this.setState({
      contactOpen: !this.state.contactOpen
    });
    
    document.body.classList.toggle('noscroll');
  }

  componentDidMount() {
    if(document.body.classList.contains('noscroll')) {
      document.body.classList.remove('noscroll');
    }
  }

  render() {
    return (
      <PageTransition
        defaultStyle={{
          transition: 'all 1100ms cubic-bezier(0.8, 0, 0.2, 1)',
          position: 'relative',
          width: '100%',
          opacity: 0,
          maxWidth: '2000px'
        }}

        transitionStyles={{
          entering: { opacity: 1 },
          entered: { opacity: 1 },
          exiting: { opacity: 0, }
        }}

        transitionTime={1250}
      >
        <Header
          headerStyle="std"
          contactOpen={this.state.contactOpen}
          action={() => this.toggleContact()}
          {...this.props }
        />

        <Hero
          pageTitleSub="our"
          pageTitleMain="solutions"
          pageIntro="our_suite_of_solutions_para"
        />

        <Products
          featSlider={featSlider}
        />

        <Services />

        <CallToAction
          image={ctaImage}
          heading="tailor_made_perfect_fit"
          content="lets_get_in_touch"
          buttonLabel="contact_us"
          buttonLink="/contact/"
        />

        <Footer />
      </PageTransition>
    );
  }
}

export default withIntl(SolutionsPage)




// ./src/pages/solutions.jsx