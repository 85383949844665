import React from 'react'

const ImageSlide = ({ image }) => (

    <div className="featslider-image__slide">
        <div className="featslider-image__wrap">
            <img src={ image } alt="Featured Image"/>
        </div>
    </div>
)

export default ImageSlide



