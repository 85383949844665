import React from 'react'
import FeatSlider from '../sliders/feature-slider/feature-slider'
import { FormattedMessage } from 'react-intl'

const Products = ({ headingSub, headingMain, headingContent, featSlider }) => (
    <section className="solprod">
        {/* Introduction */}
        {headingMain && 
          <div className="solprod-intro pad-desktop">
              <div className="row expanded">
                  {/* Headings */}
                  {headingMain &&  
                    <div className="small-12 large-push-2 large-3 columns">
                      <div className="solprod-intro__heading mpad">
                          <em><FormattedMessage id={headingSub}/></em>
                          <h2 className="dark"><FormattedMessage id={headingMain}/></h2>
                      </div>
                    </div>
                  }
                

                  {/* Introduction */}
                  {headingContent && 
                    <div className="small-12 large-pull-2 large-5 columns">
                        <div className="solprod-intro__content mpad">
                            <p className="dark"><FormattedMessage id={headingContent}/></p>
                        </div>
                    </div>
                  }
              </div>
          </div>
        }

        {/* Slider / Products */}
        <div className={headingMain ? ("solprod-slider no-print") : ("solprod-silder no-print")}>
            <FeatSlider
                featSlider = {featSlider}
            />
        </div>
    </section>
)

export default Products



